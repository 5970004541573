<template>
  <IchibanTemplate>
    <template v-slot:body>
      <div class="pa-4">
        <v-card ref="form" dark :style="{ background: 'transparent' }">
          <v-card-title></v-card-title>
          <v-card-text>
            <div class="mb-2">
              <v-text-field
                ref="name"
                label="名稱"
                outlined
                dense
                v-model="payload.name"
                validate-on-blur
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div class="mb-2">
              <v-text-field
                ref="phone"
                v-model="payload.phone"
                label="電話號碼"
                outlined
                dense
                clearable
                validate-on-blur
                :rules="[rules.required, rules.phone]"
              ></v-text-field>
            </div>
            <div class="mb-2">
              <v-text-field
                ref="address"
                v-model="payload.address"
                label="地址"
                outlined
                dense
                clearable
                validate-on-blur
                hint="將預設為獎品寄送地址，請確認填寫正確"
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div class="mb-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="birth"
                    v-model="payload.birth"
                    label="生日"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    validate-on-blur
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="payload.birth"
                  no-title
                  scrollable
                  locale="zh-tw"
                  :max="today"
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="bt-text-large"
              color="#2fd5d8"
              dark
              @click="confirm"
              :disabled="hasEdit"
            >
              儲存
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>
  </IchibanTemplate>
</template>

<script>
import liff from "@line/liff";
import util from "@/mixins/util";
import _ from "lodash";
import dayjs from "dayjs";
import { mapState } from "vuex";
import IchibanTemplate from "./IchibanTemplate.vue";

export default {
  name: "DialogIchiban",
  mixins: [util],
  props: [],
  components: { IchibanTemplate },
  data: () => ({
    _: _,
    today: dayjs().format("YYYY-MM-DD"),
    payload: {
      name: "",
      phone: "",
      birth: "",
      address: "",
    },
    menu: false,
    rules: {
      required: (value) => !!value || "必填欄位.",
      phone: (value) => {
        const taiwanPhoneRegex = /^09\d{8}$/;
        return taiwanPhoneRegex.test(value) || "電話號碼格式錯誤";
      },
    },
  }),
  async created() {
    if (!this.userID) {
      return this.$router.push({
        name: "Ichiban",
        query: { store: "@695yimoc" },
      });
    }

    try {
      await this.getUser();
      this.setPayload();
    } catch (error) {
      alert(error);
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
      images: (state) => state.images,
    }),
    hasEdit() {
      return Object.keys(this.payload).every((key) => {
        return this.payload[key] === this.user[key];
      });
    },
  },
  methods: {
    closeLiff() {
      liff.closeWindow();
    },
    setPayload() {
      if (this.user) {
        const user = this.user;
        this.payload.name = user.name;
        this.payload.phone = user.phone;
        this.payload.address = user.address;
        this.payload.birth = user.birth;
      }
    },
    async confirm() {
      let hasError = false;
      Object.keys(this.payload).forEach((f) => {
        this.$refs[f].validate(true);
        if (this.$refs[f].errorBucket.length > 0) {
          hasError = true;
        }
      });

      if (hasError) return;
      try {
        await this.axios.post(`/users/update-information/${this.user.userID}`, {
          ...this.payload,
        });
        await this.getUser();
        this.setPayload();
        this.$toast.success("更新完成");
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
    async getUser() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getUser`, payload);

        this.$store.commit("clientModule/setState", {
          user: data,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
